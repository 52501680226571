<template>
  <div >
    <el-dialog title="导入价格" :visible.sync="visible" width="460px" :before-close="handleClose" append-to-body>
      <el-form :model="form.data" ref="elFormDom" :rules="form.rules" label-position="left" size="small">
        <el-form-item label="城市" prop="city">
          <el-select v-model="form.data.city" filterable placeholder="选择城市" style="width:80%;">
            <el-option v-for="item in serveAreaArr" :label="item.city_name" :value="item.city" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            :limit="1"
            action=""
            :file-list="fileList"
            :on-remove="removeFile"
            :multiple="false"
            :http-request="httpRequestBack"
            accept=".xls,.xlsx">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <el-button class="sampleFile" type="text" @click="handleDownload">下载样表</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit" :loading="form.loading">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 导入异常弹窗 -->
    <el-dialog
      title="反馈信息"
      :visible.sync="errorDialogVisible"
      width="500px"
      center
      :before-close="errorClose"
      append-to-body
      >
        <div style="font-weight: bold;margin-bottom:10px;">
          <span>导入成功：{{templateInfo.success}} 条</span> 
          <span>，导入失败：{{templateInfo.error_num}} 条</span> 
        </div>
        <div>导入失败反馈：</div>
        <div v-if="templateInfo.errors.length > 0" class="errorBox">
          <div class="err-list" v-for="(item, index) in templateInfo.errors" :key="index">{{index +1}}、{{item}}</div>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="errorExp">下载反馈信息</el-button>
        <el-button @click="errorClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { exportData } from "@/util";
import { getStorage } from '@/storage'
export default {
  name: 'ImportDailog',
  data() {
    return {
      token: getStorage('token'),
      visible: false,
      dialogVisibleImport: false,
      form: {
        loading: false,
        data: {
          city: ''
        },
        rules: {
          city: [{ required: true, message:'请选择城市', trigger: 'change' }],
        }
      },
      fileList: [],
      curfile: '',
      // 导入后提示
      errorDialogVisible: false,  // 导入异常弹窗
      templateInfo: {
        success: 0,
        error_num: 0,
        errors: [],
      },
      listArr: [],
      serveAreaArr: [], // 下单城市
      error_file: '',
    }
  },
  methods: {
    getDetail(obj) {
      this.getsearveArea();
      console.log(obj,"obj")
      this.visible = true;
      this.objData = obj;
    },
    // 获取下单城市
    getsearveArea() {
      this.$http.get('/admin/flex_service_district/list', { params: {page:1,count:1000}}).then(res => {
        if(res.code === 1) {
          this.serveAreaArr = res.data.list;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      this.isChange = false;
      this.isCopy = false;
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    httpRequestBack(file) {
      this.curfile = file.file;
    },
    // 导入
    uploadSubmit() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          if(this.curfile) {
            let formData = new FormData();
            formData.append('city', this.form.data.city);
            formData.append('file', this.curfile);
            this.form.loading = true;
            this.$http.post('/admin/flex_item/import', {formData, type:'upload',obj:{city: this.form.data.city}}).then(res => {
              if(res.code == 1) {
                // 导入失败的就弹窗显示错误信息并能下载文本
                if(res.data.error_num > 0) {
                  this.errorDialogVisible = true; // 打开反馈错误信息弹窗
                  this.templateInfo.errors = res.data.errors; //上传失败数据
                  this.templateInfo.success = res.data.success_num; // 上传成功条数
                  this.templateInfo.error_num = res.data.error_num; // 上传失败条数
                  this.error_file = res.data.error_file; //错误信息导出文件
                } else {
                  this.$message.success('导入成功！')
                }
                this.handleClose(); // 关闭导入弹窗
              } else  {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          } else {
            this.$message.warning('请选择要导入的文件')
          }
        }
      })
    },
    // 下载错误信息
    errorExp() {
      const text = this.templateInfo.errors;
      const blob = new Blob([text], {type:'text/plain'});
      // 创建a标签元素
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob)
      link.download ='file.txt';
      // 添加到页面上进行点击下装
      document.body.appendChild(link);
      link.click();
      // 移除临时生成的a标签无素
      document.body.removeChild(link);
    },
    removeFile() {
      this.fileList = [];
    },
    // 关闭导入弹窗
    handleClose() {
      this.toggle(false)
      this.fileList = [];
      this.curfile = '';
      this.form.data.city = ''
    },
    errorClose() {
      this.errorDialogVisible = false;
    },
    // 下载样表
    handleDownload() {
      exportData({},'/admin/flex_item/downloadSample')
    },
  }
}
</script>
<style scoped>
.err-list {
  margin-top: 10px;
}
.sampleFile{
  position: absolute;
  left: 180px;
  top: 0px;
}
.sampleFile:hover {
  border-bottom: solid 1px #0376E7;
}
</style>