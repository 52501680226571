<template>
  <el-dialog
    width="680px"
    title="查看规格"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >

    <el-descriptions title="" :column="1" border>
      <el-descriptions-item label="需求类别" :labelStyle="labelStyle">{{info.parent_kind_str}} > {{info.kind_id_str}}</el-descriptions-item>
      <el-descriptions-item label="服务项目" :labelStyle="labelStyle">{{info.name}}</el-descriptions-item>
      <el-descriptions-item label="规    格" :labelStyle="labelStyle">
        <div>
          <div v-for="(v,i) in info.spec" :key="i" style="margin-top:10px;">
            <span>{{v.spec}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>{{v.amount}}元</span>
          </div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
export default {
  name: 'Detail',
  data() {
    return {
      visible: false,
      info: {
        spec:[]
      },
      labelStyle: {
        width:'80px'
      }
    }
  },
  methods: {
    // 获取详情
    getDetail(row) {
      this.visible = true;
      this.$http.get('/admin/flex_item/info', {params: {id:row.id}}).then(res => {
        if(res.code == 1) {
          this.info = res.data
        }
      })
    },
    reset(done) {
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
  }
}
</script>