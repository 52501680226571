<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="一级分类名称:" prop="parent_kind_id">
          <el-select v-model="table.params.parent_kind_id" filterable placeholder="请选择" @focus="handleKind(1)" @change="onSearch">
            <el-option
              v-for="item in parentKindOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级分类名称:" prop="kind_id">
          <el-select v-model="table.params.kind_id" filterable placeholder="请选择" @focus="handleKind(2)" @change="onSearch">
            <el-option
              v-for="item in kindOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务商品:" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入服务商品" @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item label="城市:" prop="area_arr">
          <el-cascader
            style="width:100%;"
            v-model="table.params.area_arr"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            filterable
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-select v-model="table.params.status" @change="onSearch">
            <el-option label="禁用" :value="2"></el-option>
            <el-option label="启用" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">添加</el-button>
      <el-button size="mini" type="primary" plain icon="el-icon-upload2" @click="batchImport">导入</el-button>
      <el-button size="mini" type="primary" plain @click="exportDataAll" icon="el-icon-download" >导出</el-button>
    </div>

    <div class="curTableWrap" ref="main">
      <el-table
        v-if="refreshTable"
        :data="table.data"
        :height="height"
        border
        size="medium"
        ref="refTable"
        :default-expand-all="isExpandAll"
        header-align="center"
        align="center"
        v-loading="table.loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)"
        :header-cell-style="{background:'#f5f5f5'}"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
        row-key="id"
        style="width: 100%">
        <el-table-column type="expand" style="padding:0 !important;" width="60"  >
          <template slot="header">
             <el-tooltip
                effect="dark"
                :content="isExpandAll ? `收起规格` : `展开规格`"
                placement="bottom"
              >
                <i class="el-icon-menu" color="#0376E7" style="color:#0376E7" @click="toggleExpand" ></i>
              </el-tooltip>
          </template>
          <template slot-scope="{row}">
            <div class="demo-table-expand">
              <div class="specwrap" v-for="(item,index) in row.specs" :key="index">
                <div class="specitem" style="display:flex;justify-content: space-between;">
                  <div style="width:190px !important;border-right:solid 1px #eee;">规格{{index + 1}}：</div>
                  <div style="width:440px;padding:0 3px;border-right: solid 1px #eee;text-align: center;">{{item.spec}}</div>
                  <div style="display:flex;justify-content: space-between; flex:1">
                    <div class="item">报价城市：{{item.count_city}}</div>
                    <div class="item">金额：¥ {{item.amount}}<span v-if="item.count_city > 1">起</span></div>
                    <div class="item">{{item.create_time}}</div>
                    <div class="item">
                      <span class="textBtn" style="margin-right:10px;" v-if="item.city_amount.length > 0" @click="handleGroups(row,index)">查看报价</span>
                      <span class="textBtn" @click="handleDelSpec(item)">删除规格</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column
          label="ID"
          prop="id"
          width="80">
        </el-table-column>
        <el-table-column
          label="服务商品"
          prop="name"
          align="center"
          width="220">
        </el-table-column>
        <el-table-column
          label="分类"
          prop="parent_kind_str"
          align="center"
          width="220">
          <template slot-scope="{row}">
            <span>{{row.parent_kind_str}} / </span >
            <span>{{row.kind_id_str}}</span >
          </template>
        </el-table-column>
        <el-table-column
          label="规格数量"
          prop="count"
          align="center">
        </el-table-column>
        <el-table-column
          label="状态"
          prop="status"
          align="center">
          <template slot-scope="{row}">
            <el-switch
          v-model="row.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
          @change="handleStatus(row)">
        </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="create_time"
          align="center">
        </el-table-column>
        <el-table-column
          label="操作"
          prop="active"
          align="center">
          <template slot-scope="{row}">
            <el-button type="text" style="font-size:18px" @click="showEdit(row)" icon="el-icon-edit" title="编辑商品"></el-button>
            <el-button type="text" style="font-size:18px" @click="remove(row)" icon="el-icon-delete" title="删除商品"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class='pagination__wrap'>
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="table.params.page"
          :page-size="table.params.count"
          :total="table.total"
          :page-sizes="[10, 20, 50, 100, 150]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 查看报价 -->
    <el-dialog title="查看报价" width="600px" :visible.sync="dialogFormVisible" center>
      <el-form :model="form" size="small">
        <div style="margin-bottom:20px;">
          <span>服务商品：{{form.data.name}}</span>
          <span style="margin-left:100px;">服务规格：{{form.data.spec}}</span>
        </div>
        <el-table
          :data="groupsData"
          style="width: 100%"
          :header-cell-style="{background:'#f5f5f5'}"
          border>
          <el-table-column
            type="index"
            label="序号"
            width="80">
          </el-table-column>
          <el-table-column
            prop="city_str"
            label="城市">
          </el-table-column>
          <el-table-column
            prop="amount"
            label="金额">
            <template slot-scope="{row}">
              <span>¥ {{row.amount}}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-dialog>


    <edit ref="edit" :areaArr="areaArr" @refresh="getTable"></edit>
    <detail ref="detail"></detail>

    <!-- 导入文件弹窗 -->
    <ImportDailog ref="importDailog" ></ImportDailog>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import Detail from './components/Detail.vue';
import ImportDailog from './components/importDailog.vue'
import { exportData } from "@/util";
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'BannerList',
  components: {
    VTable,
    Edit,
    Detail,
    ImportDailog
  },
  mixins: [mixinTable],
  data() {
    return {
      height: 0,
      isExpandAll: true,
      refreshTable: true,
      field: [
        { name: "name", label: "服务商品", hidden: false },
        { name: "parent_kind_str", label: "分类", hidden: false },
        { name: "count", label: "规格数量", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "create_time", label: "创建时间", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "200", hidden: false }
      ],
      multipleSelection: [],
      table: {
        loading: false,
        params: {
          name: '',
          parent_kind_id:'', // 一级工种
          kind_id: '', // 二级工种
          status: '',
          area_arr: [],
          province: '',
          city: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      parentKindOpt: [],
      kindOpt: [],
      areaArr: [],
      dialogFormVisible: false,
      groupsData: [],
      form: {
        data: {
          name: '',
          spec: '',
        }
      }
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  mounted() {
    this.height = this.$refs.main.offsetHeight || 500
  },
  methods: {
    getTable() {
      let _params = {
        ...this.table.params,
      }
      if(!!this.table.params.area_arr) {
        _params.province = _params.area_arr[0];
        _params.city = _params.area_arr[1];
      } else {
        _params.province = '';
        _params.city = '';
      }
      delete _params.area_arr;
      this.$http.get('/admin/flex_item/list', { params: _params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取分类 type 1:一级 2:二级
    handleKind(type) {
      this.$http.get('/admin/flex_kind/list',{params: {type:type,page:1,count:10000}}).then(res => {
        if(res.code == 1) {
          if(type == 1) {
            this.parentKindOpt = res.data.list; // 一级分类
          } else if (type == 2) {
            this.kindOpt = res.data.list; // 二级分类
          }
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    delete(rowid) {
      this.$http.post('/admin/flex_item/delete', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    },

    // 设置某行样式
    tableRowClassName({ row, rowIndex }) {
      if (row.spec) {
        return "success-row";
      }
      return "";
    },
    // 展开、收起规格
    toggleExpand() {
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      })
    },
    // 删除规格
    handleDelSpec(item) {
      // 若有分组价给个提示，否则直接删除
      if(item.city_amount.length > 0) {
        this.$confirm('该规格存在分组价，确定删除吗?','提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delspecFun(item);
        }).catch(() => {})
      } else  {
        this.delspecFun(item);
      }
    },
    // 删除规格方法
    delspecFun(item) {
      this.$http.post('/admin/flex_item/deleteSpec',{id:item.id}).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功')
          this.getTable();
        }
      })
    },
    // 切换状态
    handleStatus(row) {
      this.$http.post('/admin/flex_item/operation', {id:row.id}).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
        } else {
          this.$message.error(res.data)
        }
      }).finally(() => {
        this.getTable();
      })
    },
    //批量导入表格识别
    batchImport() {
      let dom = this.$refs.importDailog;
      dom.toggle(true);
      dom.getDetail();
      dom = null;
    },
    // 分组售价
    handleGroups(row,index) {
      this.dialogFormVisible = true;
      this.groupsData = row.specs[index].city_amount;
      this.form.data.name = row.name;
      this.form.data.spec =  row.specs[index].spec;
    },
    // 批量选择
    handleSelectionChange(batchData) {
      this.multipleSelection = batchData;
    },
    exportDataAll() {
      let _params = {...this.table.params};
      console.log(this.table.params,"100")
      _params.ids = this.multipleSelection.map(v => {return v.id}).join(",");
      if(!!this.table.params.area_arr && this.table.params.area_arr.length > 0) {
        _params.province = this.table.params.area_arr[0];
        _params.city = this.table.params.area_arr[1];
      } else {
        _params.province = '';
        _params.city = '';
      }
      delete _params.area_arr;
      exportData(_params, "/admin/flex_item/export");
    },
    
  }
}
</script>

<style scope lang="scss">
.el-table .success-row {
  background: #e6f1fd;
  color: #595959;
  border: none;
}
.el-table .success-row ::v-deep .el-table--border .el-table__cell {
  border-right: none !important;
}
.mr20 {
  margin-right: 20px;
}
.goodsbox {
  width: 22%;
  display: inline-block;
}
.item-row {
  margin-top: 5px;
}
.errorBox {
  max-height: 500px;
  overflow-y: scroll;
}
.curTableWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 24px;
  background: #fff;
  flex: 1;
  overflow-y: scroll;
}

.pagination__wrap {
  margin: 10px 0;
  text-align: right;
  /* padding-right: 40px; */
}
</style>
<style scoped>
::v-deep .el-table__expanded-cell{
  padding: 0 !important;
}
.specwrap {
  /* height: 40px; */
  line-height: 40px;
}
.specitem {
  border-bottom: solid 1px #eee;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.specitem .item2 {
  border-right: solid 1px #eee;
  text-align: center;
  flex: 2;
}
.specitem .item {
  border-right: solid 1px #eee;
  text-align: center;
  flex: 1;
}
.specitem .item:last-child{
  border-right: none;
}
.textBtn {
  color:#0376E7;
  cursor: pointer;
}
.textBtn:hover {
  text-decoration: underline;
}
</style>