<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop="kind_id" label="需求类别" v-if="!!isChange">
        <span class="kind_text">{{form.data.parent_kind_str}} / {{form.data.kind_id_str}}</span>
      </el-form-item>
      <el-form-item prop="kind_id" label="需求类别" v-else>
        <el-cascader
          style="width:100%;"
          ref="cascaderKinds"
          v-model="form.data.kind_id"
          :options="kindListOpt"
          :disabled="isChange"
          :props="{value:'id',label:'name', checkStrictly: false}"
          ></el-cascader>
      </el-form-item>
      <el-form-item prop='name' label='服务商品名称'>
        <el-input v-model="form.data.name" placeholder="请输入服务商品名称" clearable ></el-input>
      </el-form-item>

      <el-form-item prop='spec' label='规格' class="specLabel">
        <el-row style="margin-top:15px" v-for="(specItem, specIndex) in specArr" :key="specIndex">
          <el-col :span="12">
            <el-input v-model="specItem.spec" placeholder="请输入"></el-input>
          </el-col>
          <el-col :span="8" style="width:220px;">
            <el-button type="text" size="mini" v-if="specArr.length != 1" @click="handleMinus(specIndex)" style="margin-left:5px;color:#f56c6c;" >删除规格</el-button>
            <el-button type="text" size="mini" style="margin-left:10px;" @click="handleAdd">添加规格</el-button>
          </el-col>
          <el-col :span="24" v-if="specItem.city_amount.length > 0">
            <el-row :gutter="5"  v-for="(groupItem,groupIndex) in specItem.city_amount" :key="groupIndex">
              <el-col :span="9">
                <el-select v-model="groupItem.city" filterable placeholder="选择城市" size="mini" @change="selChange($event,specIndex,groupIndex)">
                  <el-option v-for="item in serveAreaArr" :label="item.city_name" :value="item.city" :key="item.id"></el-option>
                </el-select>
              </el-col>
              <el-col :span="9">
                <el-input v-model="groupItem.amount" placeholder="请输入" size="mini"
                  @input="groupItem.amount=groupItem.amount.match(/\d+(\.\d{0,2})?/) ? groupItem.amount.match(/\d+(\.\d{0,2})?/)[0] : ''"
                  >
                    <template slot="suffix">元</template>
                  </el-input>
              </el-col>
              <el-col :span="6">
                <el-button type="danger" icon="el-icon-minus" size="mini" circle v-if="specItem.city_amount.length != 1" @click="groupMinus(specIndex,groupIndex)" style="margin-left:5px;" ></el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini" circle @click="groupAdd(specIndex,groupIndex)"></el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'NormsEdit',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            kind_id: '',
          },
          rules: {
            name: [{ required: true, message:'请输入服务项目', trigger: 'change' }],
            kind_id: [{ required: true, message:'请选择需求类别', trigger: 'change' }],
          }
        },
        specArr: [
          {
            spec: '',
            id: '',
            city_amount: [
              {
                city:'',
                amount: ''
              }
            ],
          }
        ],
        kindListOpt: [],
        serveAreaArr: [], // 下单城市
      }
    },
    methods: {
      // 获取详情
      async getDetail(row) {
        await this.getkindList();
        await this.getsearveArea(); // 下单城市
        if (!!row.id) {
          this.isChange = true;
          await this.$http.get('/admin/flex_item/info', {params: {id:row.id}}).then(res => {
          if(res.code == 1) {
            this.form.data = res.data;
            this.form.data.kind_id = [res.data.parent_kind_id, res.data.kind_id]
            if(res.data.spec && res.data.spec.length > 0) {
              this.specArr = res.data.spec.map(item => {
                return {
                  ...item,
                  city_amount: item.city_amount.map(v => {
                    return {
                      ...v,
                      city: Number(v.city)
                    }
                  })
                }
              });
            } else {
              this.specArr = [
                {
                  spec: '',
                  id: '',
                  city_amount: [
                    {
                      city:'',
                      amount: ''
                    }
                  ],
                }
              ]
            }
          }
        })
        }
      },
      // 获取下单城市
      getsearveArea() {
        this.$http.get('/admin/flex_service_district/list', { params: {page:1,count:1000}}).then(res => {
          if(res.code === 1) {
            this.serveAreaArr = res.data.list;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.specArr = [
          {
            spec: '',
            id: '',
            city_amount: [
              {
                city:'',
                amount: ''
              }
            ],
          }
        ],
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      selChange(value,specIndex,groupIndex) {
        let arr = this.specArr[specIndex].city_amount.filter(j => j.city === value)
        if(arr.length > 1) {
          this.$message.warning('该城市已设置请勿重复添加')
          this.specArr[specIndex].city_amount[groupIndex].city = '';
        }
      },
      hasDuplicateIds(arrays) {
        return arrays.some(subArray => {
          const ids = subArray.city_amount.map(item => item.city);
          return ids.some((city, index) => ids.indexOf(city) !== index);
        });
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            console.log(this.specArr,"this.specArr")
            // const data = [
            //   {city_amount: [{ city: 1, name: 'Alice' }, { city: 2, name: 'Bob' }]},
            //   {city_amount: [{ city: 3, name: 'Charlie' }, { city: 4, name: 'David' }]},
            // ];
            console.log(this.hasDuplicateIds(this.specArr),"***---")
            // 判断数组中的子级数组是否选了相同的城市
            if(this.hasDuplicateIds(this.specArr) == true) {
              this.$message.warning("一个规格里面的城市不可重复添加")
              return
            }
            // return
            let _params = {
              id: this.form.data.id,
              kind_id: [...this.form.data.kind_id].pop(),
              name: this.form.data.name,
              spec: JSON.stringify(this.specArr)
            }
            let apiUrl = !this.isChange ? '/admin/flex_item/add' : '/admin/flex_item/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 获取服务分类
      getkindList() {
        // 下单模式 1-发布需求 2-购买服务
        this.$http.get('/admin/flex_kind/all',{params:{order_mode:2}}).then(res => {
          if(res.code == 1) {
            this.kindListOpt = res.data;
            console.log(this.kindListOpt,"9999")
          }
        })
      },
      // 减规格
      handleMinus(index) {
        if(this.specArr.length > 1) {
          this.specArr.forEach((v,i) => {
            if(index == i) {
              this.specArr.splice(index, 1)
            }
          })
        }
      },
      // 加规格
      handleAdd() {
        if(this.specArr.length < 10) {
          let str = {
            spec: '',
            id: '',
            city_amount: [{city:'',amount: ''}],
          }
          this.specArr.push(str)
        }
      },
      // 减城市
      groupMinus(specIndex,groupIndex) {
        if(this.specArr[specIndex].city_amount.length > 0) {
          this.specArr[specIndex].city_amount.forEach((v,i) => {
            if(groupIndex == i) {
              this.specArr[specIndex].city_amount.splice(groupIndex, 1)
            }
          })
        }
      },
      // 加城市
      groupAdd(specIndex) {
        if(this.specArr[specIndex].city_amount.length < 10) {
          let str = {city:'',amount: '',}
          this.specArr[specIndex].city_amount.push(str)
        }
      },
    }
  }
</script>
<style >
.specLabel .el-form-item__label::before {
  content: "*";
  color: #F56C6C;
  margin-right: 4px;
}
.kind_text {
  border: 1px solid rgb(238, 238, 238);
  height: 35px;
  display: inline-block;
  line-height: 35px;
  width: 95%;
  padding: 0 10px;
  border-radius: 5px;
  background: #f5f7fa;
}
</style>